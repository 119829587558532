<template>
  <card-component :title="`${title} - ${computedData.datas.key || ''}`">
    <table-component
      :columns="computedData.columns"
      :data="dataLastMonth.details"
      @change="handleSort"
    >
      <template #affiliate="{ column }">
        <i class="man"></i>
        {{ column }}
      </template>
      <template #visitor="{ record }">
        {{ record.commission.visitor }}
      </template>
      <template #subscribe_freemium="{ record }">
        {{ record.commission.subscribe_freemium }}
      </template>
      <template #subscribe="{ record }">
        {{ record.commission.subscribe }}
      </template>
      <template #gains="{ record }">
        <span v-thousand> {{ record.commission.gains }} € </span>
      </template>
      <template v-if="dataLastMonth.all" #footer>
        <table-component
          :columns="computedData.affiliationColumns"
          :data="[dataLastMonth.all]"
          :header="false"
        >
          <template #date="{ column }">
            <i class="calendar"></i>
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #visitor="{ column }">
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #subscribe_freemium="{ column }">
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #subscribe="{ column }">
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #gains="{ column }">
            <span class="td-footer-info" v-thousand> {{ column }} € </span>
          </template>
        </table-component>
      </template>
    </table-component>
  </card-component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import { mapActions, mapGetters } from 'vuex'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'

@Options({
  name: 'DilypseResellerCommission',
  components: {
    CardComponent,
    TableComponent,
  },
  props: {
    title: {
      type: String,
      default: 'Commissions à régler',
    },
    storeOption: {
      type: Object,
      default: () => ({
        getter: 'getDashboardResellerPay',
        type: 'lastCommission',
      }),
    },
  },
  computed: {
    ...mapGetters(['getDashboardResellerPay', 'getResellerSession']),
    computedData() {
      return this[this.storeOption.getter] || { datas: {} }
    },
    dataLastMonth() {
      const { last_month } = this.computedData.datas
      const defaultData = { details: [] }
      return this.isEmpty(last_month) ? defaultData : last_month || defaultData
    },
  },
  methods: {
    ...mapActions(['setResellerCommission']),
    isEmpty(data: unknown): boolean {
      return data instanceof Array && data.length === 0
    },
    handleSort({ column: { columnName }, order }: ISearchParams) {
      let key = 'subscribeCommission'
      switch (columnName) {
        case 'affiliate':
          key = 'affiliateCommission'
          break
        case 'visitor':
          key = 'visitorCommission'
          break
        case 'gains':
          key = 'gainsCommission'
          break
      }
      this.setResellerCommission([
        {
          key,
          order: order.replace('end', ''),
        },
        this.storeOption.type,
      ])
    },
  },
})
export default class DilypseResellerCommission extends Vue {}
</script>
