<template>
  <div class="body-content row">
    <div class="col-lg-3">
      <month-statistic :month="statisticData.key" class="mb-5" />
      <side-card-component :count="statisticData.visitor || 0" icon="screen">
        visiteur(s) <br />
        généré(s)
      </side-card-component>
      <side-card-component
        :count="statisticData.subscribe_freemium || 0"
        icon="task"
      >
        souscription(s) <br />
        freemium
      </side-card-component>
      <side-card-component :count="statisticData.subscribe || 0" icon="task">
        souscription(s) <br />
        payée(s)
      </side-card-component>

      <side-card-component
        :count="statisticData.gains || 0"
        unit="€"
        icon="calculator"
      >
        # Gagné(s)
      </side-card-component>
    </div>
    <div class="col-lg-9">
      <dilypse-reseller-commission
        title="Statistiques"
        :store-option="{
          getter: 'getResellerSession',
          type: 'currentCommission',
        }"
      ></dilypse-reseller-commission>
      <dilypse-reseller-commission></dilypse-reseller-commission>
      <dilypse-reseller-commission-history></dilypse-reseller-commission-history>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

import DilypseResellerCommission from './DilypseStatisticTab/DilypseResellerCommission.vue'
import DilypseResellerCommissionHistory from './DilypseStatisticTab/DilypseResellerCommissionHistory.vue'
import SideCardComponent from '@/components/DataDisplay/SideCard/SideCard.vue'
import MonthStatistic from '@/components/DataDisplay/MonthStatistic/MonthStatistic.vue'

@Options({
  name: 'DilypseResellerStatistic',
  components: {
    DilypseResellerCommission,
    DilypseResellerCommissionHistory,
    SideCardComponent,
    MonthStatistic,
  },
  computed: {
    ...mapGetters(['getDashboardReseller']),
    computedData() {
      return this.getDashboardReseller || { datas: {} }
    },
    statisticData() {
      return this.computedData.datas.currentMonth || { key: '' }
    },
  },
  mounted() {
    this.setResellerCommission([])
    this.setResellerCommission([{}, 'currentCommission'])
    this.setResellerCommissionHistory()
  },
  methods: {
    ...mapActions(['setResellerCommission', 'setResellerCommissionHistory']),
  },
})
export default class DilypseResellerStatistic extends Vue {}
</script>
