
import { Options, Vue } from 'vue-class-component'
import moment from 'moment'

moment.locale('fr-FR')

interface MonthStatisticProps {
  month: {
    type: string
    default: string
  }
}

@Options({
  props: ({
    month: {
      type: String,
      default: '',
    },
  } as unknown) as MonthStatisticProps,
  computed: {
    currentMonth() {
      let currentMonth = moment(moment.now()).format('MMMM YYYY')
      let currentMonthFormatted = currentMonth.split('')
      currentMonthFormatted[0] = currentMonthFormatted[0].toUpperCase()

      return currentMonthFormatted.join('')
    },
  },
})
export default class MonthStatistic extends Vue {}
