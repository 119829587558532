
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    count: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
  },
})
export default class SideCardComponent extends Vue {}
