
import { Options, Vue } from 'vue-class-component'
import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'
import Switch from 'ant-design-vue/lib/switch'

@Options({
  name: 'DilypseResellerCommissionHistory',
  components: {
    CardComponent,
    TableComponent,
    ASwitch: Switch,
  },
  computed: {
    ...mapGetters(['getDashboardReseller', 'getShowAffiliate']),
    computedData() {
      return this.getDashboardReseller || { datas: {} }
    },
    dataByMonth() {
      const { byMonth } = this.computedData.datas
      return byMonth instanceof Array ? byMonth : byMonth || []
    },
  },
  data() {
    return {
      showAffiliate: this.getShowAffiliate,
    }
  },
  mounted() {
    this.showAffiliate = this.getShowAffiliate
  },
  methods: {
    ...mapActions(['setDisplayAffiliate', 'setResellerCommissionHistory']),
    customClassName(record: any) {
      let cls = ''
      if (record.complete_name === '') {
        cls += ' blue-column '
      }
      if (record.visible === false) {
        cls += ' d-none '
      }

      return cls
    },
    handleDisplayAffiliate(value: boolean) {
      this.setDisplayAffiliate(value)
    },
    handleSort(sort: ISearchParams) {
      this.setResellerCommissionHistory({
        key: sort.column.columnName,
        order: sort.order.replace('end', ''),
      })
    },
  },
})
export default class DilypseResellerCommissionHistory extends Vue {}
