
import { Options, Vue } from 'vue-class-component'
import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import { mapActions, mapGetters } from 'vuex'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'

@Options({
  name: 'DilypseResellerCommission',
  components: {
    CardComponent,
    TableComponent,
  },
  props: {
    title: {
      type: String,
      default: 'Commissions à régler',
    },
    storeOption: {
      type: Object,
      default: () => ({
        getter: 'getDashboardResellerPay',
        type: 'lastCommission',
      }),
    },
  },
  computed: {
    ...mapGetters(['getDashboardResellerPay', 'getResellerSession']),
    computedData() {
      return this[this.storeOption.getter] || { datas: {} }
    },
    dataLastMonth() {
      const { last_month } = this.computedData.datas
      const defaultData = { details: [] }
      return this.isEmpty(last_month) ? defaultData : last_month || defaultData
    },
  },
  methods: {
    ...mapActions(['setResellerCommission']),
    isEmpty(data: unknown): boolean {
      return data instanceof Array && data.length === 0
    },
    handleSort({ column: { columnName }, order }: ISearchParams) {
      let key = 'subscribeCommission'
      switch (columnName) {
        case 'affiliate':
          key = 'affiliateCommission'
          break
        case 'visitor':
          key = 'visitorCommission'
          break
        case 'gains':
          key = 'gainsCommission'
          break
      }
      this.setResellerCommission([
        {
          key,
          order: order.replace('end', ''),
        },
        this.storeOption.type,
      ])
    },
  },
})
export default class DilypseResellerCommission extends Vue {}
