
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

import DilypseResellerCommission from './DilypseStatisticTab/DilypseResellerCommission.vue'
import DilypseResellerCommissionHistory from './DilypseStatisticTab/DilypseResellerCommissionHistory.vue'
import SideCardComponent from '@/components/DataDisplay/SideCard/SideCard.vue'
import MonthStatistic from '@/components/DataDisplay/MonthStatistic/MonthStatistic.vue'

@Options({
  name: 'DilypseResellerStatistic',
  components: {
    DilypseResellerCommission,
    DilypseResellerCommissionHistory,
    SideCardComponent,
    MonthStatistic,
  },
  computed: {
    ...mapGetters(['getDashboardReseller']),
    computedData() {
      return this.getDashboardReseller || { datas: {} }
    },
    statisticData() {
      return this.computedData.datas.currentMonth || { key: '' }
    },
  },
  mounted() {
    this.setResellerCommission([])
    this.setResellerCommission([{}, 'currentCommission'])
    this.setResellerCommissionHistory()
  },
  methods: {
    ...mapActions(['setResellerCommission', 'setResellerCommissionHistory']),
  },
})
export default class DilypseResellerStatistic extends Vue {}
