<template>
  <card-component title="Historiques des commissions">
    <template #header>
      <div class="j-switcher">
        <span class="label"> Afficher les affiliés </span>
        <span>
          <a-switch
            v-model:checked="showAffiliate"
            @change="handleDisplayAffiliate"
          />
        </span>
      </div>
    </template>
    <table-component
      :columns="computedData.columns"
      :data="dataByMonth"
      :rowClassName="customClassName"
      @change="handleSort"
    >
      <template #affiliate="{ record, column }">
        <span
          class="d-flex align-items-center"
          v-if="record.complete_name === ''"
        >
          <i class="calendar-blue"></i>
          {{ record.commission.date }}
        </span>
        <span class="d-flex align-items-center" v-else>
          <i class="man"></i>
          {{ column }}
        </span>
      </template>
      <template #visitor="{ record }">
        {{ record.commission.visitor }}
      </template>
      <template #subscribe="{ record }">
        {{ record.commission.subscribe }}
      </template>
      <template #subscribe_freemium="{ record }">
        {{ record.commission.subscribe_freemium }}
      </template>
      <template #gains="{ record }">
        {{ record.commission.gains }}
      </template>
      <template #footer>
        <table-component
          :columns="computedData.affiliationColumns"
          :data="[computedData.datas.byYear]"
          :header="false"
        >
          <template #date="{ column }">
            <i class="calendar"></i>
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #visitor="{ column }">
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #subscribe="{ column }">
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #subscribe_freemium="{ column }">
            <span class="td-footer-info">
              {{ column }}
            </span>
          </template>
          <template #gains="{ column }">
            <span class="td-footer-info" v-thousand> {{ column }} € </span>
          </template>
        </table-component>
      </template>
    </table-component>
  </card-component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'
import Switch from 'ant-design-vue/lib/switch'

@Options({
  name: 'DilypseResellerCommissionHistory',
  components: {
    CardComponent,
    TableComponent,
    ASwitch: Switch,
  },
  computed: {
    ...mapGetters(['getDashboardReseller', 'getShowAffiliate']),
    computedData() {
      return this.getDashboardReseller || { datas: {} }
    },
    dataByMonth() {
      const { byMonth } = this.computedData.datas
      return byMonth instanceof Array ? byMonth : byMonth || []
    },
  },
  data() {
    return {
      showAffiliate: this.getShowAffiliate,
    }
  },
  mounted() {
    this.showAffiliate = this.getShowAffiliate
  },
  methods: {
    ...mapActions(['setDisplayAffiliate', 'setResellerCommissionHistory']),
    customClassName(record: any) {
      let cls = ''
      if (record.complete_name === '') {
        cls += ' blue-column '
      }
      if (record.visible === false) {
        cls += ' d-none '
      }

      return cls
    },
    handleDisplayAffiliate(value: boolean) {
      this.setDisplayAffiliate(value)
    },
    handleSort(sort: ISearchParams) {
      this.setResellerCommissionHistory({
        key: sort.column.columnName,
        order: sort.order.replace('end', ''),
      })
    },
  },
})
export default class DilypseResellerCommissionHistory extends Vue {}
</script>
