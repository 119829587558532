<template>
  <div class="side-card">
    <div class="img-rounded">
      <img :src="require(`@/assets/icons/${icon}.svg`)" alt="" />
    </div>
    <div class="number" v-thousand>
      {{ count }} {{ unit !== '' ? unit : '' }}
    </div>
    <div class="text-min">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    count: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
  },
})
export default class SideCardComponent extends Vue {}
</script>

<style scoped>
.side-card {
  margin-bottom: 16px;
}
</style>
